import React from 'react';
import Header from 'Components/Header';
import Dashboard from 'Components/Dashboard';

const RootPage: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <body className="App-body">
        <Dashboard />
      </body>
    </div>
  );
};

export default RootPage;
