import { slideURL } from "utils/url";
import {
    faCode,
    faFilePdf,
    faNewspaper,
    faUpRightAndDownLeftFromCenter,
    faVideo
} from '@fortawesome/free-solid-svg-icons';
import Icon from "./Icon";

export interface CardProps {
    lectureName?: string
}

export default function Card(props: CardProps) {
    const { lectureName } = props;
    const url = slideURL(lectureName || 'template');

    return <div className="card">
        <iframe className="card-embed" src={url} />
        <CardMenu url={url} />
    </div>
}

export interface CardMenuProps {
    url?: string
}

export function CardMenu(props: CardMenuProps) {
    return <div className="card-menu">
        <a href={props.url}>
            <Icon icon={faUpRightAndDownLeftFromCenter} />
        </a>
        <Icon icon={faFilePdf} inactive={true} />
        <Icon icon={faVideo} inactive={true} />
        <Icon icon={faNewspaper} inactive={true} />
        <Icon icon={faCode} inactive={true} />
    </div>
}