import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';

import RoutingManager from 'Components/Routing/RoutingManager';

function App() {
  return (
    <Router>
      {/* Test */}
      <div className="App">
        <RoutingManager />
      </div>
    </Router>
  );
}

export default App;
