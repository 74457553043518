import React from 'react';

interface PageProps {
  component: React.ComponentType<any>;
  title: string;
}

const Page: React.FC<PageProps> = ({ component: Component, title }) => {
  React.useEffect(() => {
    document.title = `IVIA Lab | ${title}` || 'IVIA Lab';
  }, [title]);

  return <Component />;
};

export default Page;
