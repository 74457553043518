import Card from "./Card";
import * as _ from "lodash";

export default function Dashboard() {
    return <div className="dashboard">
        <Card lectureName="1_html" />
        <Card lectureName="2_css" />
        <Card lectureName="3_javascript" />
        <Card lectureName="4_typescript" />
    </div>
}