import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Page from 'Components/Routing/Page';
import RootPage from 'Pages/Root/RootPage';

const RoutingManager = () => {
  return (
    <Routes>
      <Route path="/" element={<Page component={RootPage} title="Courses Platform" />} />
    </Routes>
  );
};

export default RoutingManager;
