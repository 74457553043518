import {
    FontAwesomeIcon,
    FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

export interface IconProps extends FontAwesomeIconProps {
    inactive?: boolean
}

export default function Icon(props: IconProps) {
    const size = props.size || "lg";
    const className = `icon ${props.inactive ? 'inactive' : 'active'}`
    return <FontAwesomeIcon
        className={className}
        icon={props.icon}
        size={size}
        color="#ccc"
    />
}